import { v4 as uuidv4 } from 'uuid';
import { CategoryGroup, CategoryItem, } from './categoryTree';
class BasePropertyDef {
    constructor(params) {
        this.id = params.id === undefined ? uuidv4() : params.id;
        this.name = params.name;
        this.description = params.description;
        this.type = params.type;
        this.perFrame = params.perFrame;
        this.renderValue = params.renderValue === undefined ? false : params.renderValue;
    }
}
class ChoicePropertyDef extends BasePropertyDef {
    constructor(params) {
        super({
            id: params.id,
            name: params.name,
            description: params.description,
            type: params.type,
            perFrame: params.perFrame,
            renderValue: params.renderValue,
        });
        this.defaultValue = params.defaultValue;
        this.required = params.required;
        this.options = params.options;
    }
    summarize() {
        return {
            name: this.name,
            type: this.type,
            options: this.options.map(option => option.summarize()),
        };
    }
    static fromJSONObject(obj) {
        return new ChoicePropertyDef({
            id: obj.id,
            name: obj.name,
            description: obj.description,
            type: obj.type,
            perFrame: obj.perFrame,
            renderValue: obj.renderValue,
            defaultValue: obj.defaultValue,
            required: obj.required,
            options: obj.options.map((el) => el.children === undefined
                ? CategoryItem.fromJSONObject(el)
                : CategoryGroup.fromJSONObject(el)),
        });
    }
}
class TextPropertyDef extends BasePropertyDef {
    constructor(params) {
        super({
            id: params.id,
            name: params.name,
            description: params.description,
            type: params.type,
            perFrame: params.perFrame,
            renderValue: params.renderValue,
        });
        this.defaultValue = params.defaultValue;
        this.blank = params.blank;
        this.constraints = params.constraints;
    }
    summarize() {
        return {
            name: this.name,
            type: this.type,
        };
    }
    static fromJSONObject(obj) {
        return new TextPropertyDef({
            id: obj.id,
            name: obj.name,
            description: obj.description,
            type: obj.type,
            perFrame: obj.perFrame,
            renderValue: obj.renderValue,
            defaultValue: obj.defaultValue,
            blank: obj.blank,
            constraints: obj.constraints,
        });
    }
}
function propertyDefRouter(obj) {
    if (obj.type === 'free response') {
        return TextPropertyDef;
    }
    return ChoicePropertyDef;
}
export { ChoicePropertyDef, propertyDefRouter, TextPropertyDef };
