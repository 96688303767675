import { v4 as uuidv4 } from 'uuid';
import { facialKeypointsTemplateArgs, humanPoseTemplateArgs } from '../fixtures/keypointTemplates';
class KeypointDef {
    constructor(params) {
        this.id = params.id === undefined ? uuidv4() : params.id;
        this.name = params.name;
        this.points = params.points;
        this.edges = params.edges;
        this.allowValidInvisibles = params.allowValidInvisibles;
    }
    static fromJSONObject(obj) {
        return new KeypointDef({
            id: obj.id,
            name: obj.name,
            points: obj.points,
            edges: obj.edges,
            allowValidInvisibles: obj.allowValidInvisibles,
        });
    }
    summarize() {
        return {
            name: this.name,
            points: this.points.map(point => point.name),
        };
    }
    static listTemplates() {
        return [
            {
                name: 'Face',
                img: 'https://asset.superb-ai.com/assets/img/keypoint_saba/facial_keypoints_template_15.png',
                keypointDef: new KeypointDef(facialKeypointsTemplateArgs),
            },
            {
                name: 'Human',
                img: 'https://asset.superb-ai.com/assets/img/keypoint_saba/human_pose_template_17.png',
                keypointDef: new KeypointDef(humanPoseTemplateArgs),
            },
        ];
    }
}
export { KeypointDef };
