import { v4 as uuidv4 } from 'uuid';
import { OBJECT_DETECTION_ENGINE_INFOS } from '../../common/aiEngines';
import { propertyDefRouter } from './propertyDef';
class ObjectClassDef {
    constructor(params) {
        this.id = params.id === undefined ? uuidv4() : params.id;
        this.name = params.name;
        this.annotationType = params.annotationType;
        this.color = params.color;
        this.properties = params.properties;
        this.constraints = params.constraints;
        this.keypointInterfaceId = params.keypointInterfaceId;
        this.aiClassMap = params.aiClassMap;
        this.config = params === null || params === void 0 ? void 0 : params.config;
    }
    static fromJSONObject(obj) {
        return new ObjectClassDef({
            id: obj.id,
            name: obj.name,
            annotationType: obj.annotationType,
            color: obj.color,
            properties: obj.properties.map((el) => propertyDefRouter(el).fromJSONObject(el)),
            constraints: obj.constraints,
            keypointInterfaceId: obj.keypointInterfaceId,
            aiClassMap: obj.aiClassMap,
            config: obj.config,
        });
    }
    static listAiEngines() {
        return OBJECT_DETECTION_ENGINE_INFOS;
    }
}
class ObjectGroupDef {
    constructor(params) {
        this.id = params.id === undefined ? uuidv4() : params.id;
        this.name = params.name;
        this.objectClassIds = params.objectClassIds;
    }
    static fromJSONObject(obj) {
        return new ObjectGroupDef({
            id: obj.id,
            name: obj.name,
            objectClassIds: obj.objectClassIds,
        });
    }
}
export { ObjectClassDef, ObjectGroupDef };
