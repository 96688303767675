import { v4 as uuidv4 } from 'uuid';
import { CategoryItem } from './categoryTree';
class ChoicePropertyDef {
    constructor(params) {
        this.id = params.id === undefined ? uuidv4() : params.id;
        this.name = params.name;
        this.type = params.type;
        this.defaultValue = params.defaultValue;
        this.required = params.required;
        this.options = params.options;
    }
    static createFromLegacy(property) {
        const optionsId = property.value.reduce((idMap, val) => (Object.assign(Object.assign({}, idMap), { [val]: uuidv4() })), {});
        const options = property.value.map((val) => CategoryItem.createFromLegacy(optionsId[val], val));
        const defaultValueIds = property.default_value.value.map((val) => optionsId[val]);
        return new ChoicePropertyDef({
            name: property.name,
            type: property.duplicated ? 'Multiple Selection' : 'Multiple Choice',
            defaultValue: defaultValueIds,
            required: !property.nullable,
            options: options,
        });
    }
    toLegacy() {
        const optionIdToName = this.options.reduce((idMap, option) => (Object.assign(Object.assign({}, idMap), { [option.id]: option.name })), {});
        const defaultValue = this.defaultValue.map(val => optionIdToName[val]);
        return {
            name: this.name,
            type: 'objective',
            value: this.options.map(option => option.name),
            nullable: !this.required,
            duplicated: this.type === 'Multiple Choice' ? false : true,
            default_value: { value: defaultValue },
        };
    }
    summarizeToJSON() {
        return {
            name: this.name,
            type: this.type,
            options: this.options.map(el => el.summarizeToJSON()),
        };
    }
}
class TextPropertyDef {
    constructor(params) {
        this.id = params.id === undefined ? uuidv4() : params.id;
        this.name = params.name;
        this.type = params.type;
        this.defaultValue = params.defaultValue;
        this.blank = params.blank || false;
        this.config = params.config;
    }
    static createFromLegacy(property) {
        return new TextPropertyDef({
            name: property.name,
            type: 'Free Response',
            defaultValue: property.default_value.value,
            blank: property.nullable,
            config: {
                numbersOnly: property.number,
                uniqueInLabel: property.uniq,
                displayValue: property.displayed,
            },
        });
    }
    toLegacy() {
        return Object.assign(Object.assign(Object.assign(Object.assign({ name: this.name, type: 'string', default_value: { value: this.defaultValue } }, (this.blank === true && { nullable: true })), (this.config.numbersOnly !== undefined && { number: this.config.numbersOnly })), (this.config.uniqueInLabel !== undefined && { uniq: this.config.uniqueInLabel })), (this.config.displayValue !== undefined && { displayed: this.config.displayValue }));
    }
    summarizeToJSON() {
        return {
            name: this.name,
            type: this.type,
        };
    }
}
export { ChoicePropertyDef, TextPropertyDef };
