import { VERSION } from '../../common/index';
import { ValidationError } from '../../exceptions';
import { CategorizationDef } from './categorizationDef';
import { ObjectTrackingDef } from './objectTrackingDef';
class LabelResultDef {
    constructor(params) {
        this.version = params.version || VERSION;
        this.type = 'video-siesta';
        this.dataType = params.dataType;
        this.objectTracking = params.objectTracking;
        this.categorization = params.categorization;
        this.aiAdvancedSettings = params.aiAdvancedSettings || {
            tracking: true,
            frequencyFilter: true,
        };
    }
    summarize() {
        return {
            dataType: this.dataType,
            objectTracking: this.objectTracking.summarize(),
            categorization: this.categorization.summarize(),
        };
    }
    static fromJSONString(jsonStr) {
        try {
            return this.fromJSONObject(JSON.parse(jsonStr));
        }
        catch (e) {
            if (e instanceof ValidationError) {
                throw e;
            }
            throw new ValidationError('LabelResultDef JSON parse error');
        }
    }
    static fromJSONObject(obj) {
        // may check type, version from jsonObj.version
        return new LabelResultDef({
            version: obj.version,
            dataType: obj.dataType,
            objectTracking: ObjectTrackingDef.fromJSONObject(obj.objectTracking),
            categorization: CategorizationDef.fromJSONObject(obj.categorization),
            aiAdvancedSettings: obj.aiAdvancedSettings,
        });
    }
}
export { LabelResultDef };
