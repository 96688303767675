import { ValidationError } from '../../exceptions';
import { CategoryGroup, CategoryItem } from './categoryTree';
// this is temporary implementation
// TODO: replace anys with custom type
class LabelResult {
    constructor(params) {
        this.labelResultDef = params.labelResultDef;
        this.objectDetection = params.objectDetection;
        this.imageCategorization = params.imageCategorization;
        this.userLogs = params.userLogs;
    }
    /**
     * Convert raw label result JSON to LabelResult instance
     * TODO: consider more than legacy type, should we propagate type into subinstances
     * @param labelResultDef LabelResultDef of current project
     * @param labelResult Raw label result JSON
     * @returns LabelResult instance
     */
    static createFromLegacy(labelResultDef, labelResult) {
        try {
            if (labelResult === null || Object.keys(labelResult).length === 0) {
                throw new ValidationError('Invalid null object');
            }
            // Object Detection
            const objectDetection = labelResult.objects;
            const classNameToShape = labelResultDef.objectDetection.objectClasses.reduce((acc, objectClass) => (Object.assign(Object.assign({}, acc), { [objectClass.name]: objectClass.annotationType })), []);
            objectDetection.forEach((el) => {
                // is ID number?
                if (typeof el.id !== 'number') {
                    throw new Error('ID is not a number');
                }
                // is class Valid?
                if (!(el.class in classNameToShape)) {
                    throw new Error('class is not valid');
                }
                // is shape valid?
                if (!(classNameToShape[el.class] in el.shape)) {
                    throw new Error('shape is not valid');
                }
                // TODO: check annotation format, properties format, unique id validation
                // TODO: fill messages
            });
            // Image Categorization
            let imageCategorization = null;
            if (labelResultDef.imageCategorization.tasks.length == 0) {
                // no task, OK
                if ('categorization' in labelResult)
                    imageCategorization = labelResult.categorization; // some default value
            }
            else {
                imageCategorization = labelResult.categorization;
                const options_root = labelResultDef.imageCategorization.tasks[0]
                    .options;
                for (const val of imageCategorization.value) {
                    let options = options_root;
                    const ids = val.split(',');
                    for (let idx = 0; idx < ids.length; ++idx) {
                        options = options.filter(option => option.id === ids[idx]);
                        if (options.length != 1) {
                            throw new Error('id not found');
                        }
                        const option = options[0];
                        if (idx === ids.length - 1) {
                            if (option instanceof CategoryGroup) {
                                throw new Error('the last ID should be an item');
                            }
                            break;
                        }
                        if (option instanceof CategoryItem) {
                            throw new Error('intermediate IDs should be a group');
                        }
                        options = option.children;
                    }
                }
            }
            const userLogs = {}; // Nothing for now
            return new LabelResult({
                labelResultDef,
                objectDetection,
                imageCategorization,
                userLogs,
            });
        }
        catch (e) {
            if (e instanceof ValidationError) {
                // Just relay
                throw e;
            }
            // TODO: need to define detailed messages
            throw new ValidationError('labelResult JSON format error');
        }
    }
    toLegacy() {
        return {
            objects: this.objectDetection,
            categorization: this.imageCategorization,
        };
    }
    toJSON() {
        if (this.labelResultDef.type === 'legacyImage') {
            return this.toLegacy();
        }
        // add more types
        return {};
    }
    // TODO: summaryToJSON() {}
    /**
     * Generate tag data for given label result
     * @returns Tag of given label result
     */
    generateTags() {
        // should be dealt with proper objects later... but now we use raw JSON
        const classes = new Set(), classesCount = {};
        if (this.imageCategorization && 'value' in this.imageCategorization) {
            for (const category of this.imageCategorization.value) {
                classes.add(category);
            }
        }
        for (const objectClass of this.objectDetection) {
            const name = objectClass.class;
            classes.add(name);
            const count = classesCount[name] || 0;
            classesCount[name] = count + 1;
        }
        return {
            class: Array.from(classes),
            classes_count: Object.keys(classesCount).map((name) => ({
                name: name,
                count: classesCount[name],
            })),
        };
    }
    generateMeta() {
        if (this.labelResultDef.type === 'legacyImage') {
            const classProps = {};
            for (const classDef of this.labelResultDef.objectDetection.objectClasses) {
                classProps[classDef.name] = {
                    type: classDef.annotationType,
                    color: classDef.color,
                };
            }
            const labelMetaObjects = [];
            for (const labelObject of this.objectDetection) {
                labelMetaObjects.push({
                    id: labelObject.id,
                    type: classProps[labelObject.class].type,
                    color: classProps[labelObject.class].color,
                    locked: false,
                    visible: true,
                    selected: false,
                });
            }
            return {
                objects: labelMetaObjects,
            };
        }
        throw new Error('Not implemented');
    }
}
export { LabelResult };
