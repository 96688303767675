class SingleChoiceProperty {
    constructor(params) {
        this.type = params.type || 'radio';
        this.propertyId = params.propertyId;
        this.propertyName = params.propertyName;
        this.optionId = params.optionId;
        this.optionName = params.optionName;
    }
    static fromJSONObject(obj) {
        return new SingleChoiceProperty({
            type: obj.type,
            propertyId: obj.propertyId,
            propertyName: obj.propertyName,
            optionId: obj.optionId,
            optionName: obj.optionName,
        });
    }
}
class MultipleChoiceProperty {
    constructor(params) {
        this.type = params.type || 'checkbox';
        this.propertyId = params.propertyId;
        this.propertyName = params.propertyName;
        this.optionIds = params.optionIds;
        this.optionNames = params.optionNames;
    }
    static fromJSONObject(obj) {
        return new MultipleChoiceProperty({
            type: obj.type,
            propertyId: obj.propertyId,
            propertyName: obj.propertyName,
            optionIds: obj.optionIds,
            optionNames: obj.optionNames,
        });
    }
}
class TextProperty {
    constructor(params) {
        this.type = params.type || 'free response';
        this.propertyId = params.propertyId;
        this.propertyName = params.propertyName;
        this.value = params.value;
    }
    static fromJSONObject(obj) {
        return new TextProperty({
            type: obj.type,
            propertyId: obj.propertyId,
            propertyName: obj.propertyName,
            value: obj.value,
        });
    }
}
function propertyRouter(obj) {
    if (obj.type === 'radio' || obj.type === 'dropdown') {
        return SingleChoiceProperty;
    }
    else if (obj.type === 'checkbox') {
        return MultipleChoiceProperty;
    }
    return TextProperty;
}
export { MultipleChoiceProperty, propertyRouter, SingleChoiceProperty, TextProperty };
