export default `
- id: kitti_20230531
  name: KITTI AI
  classes:
    - id: 1
      name: Car
    - id: 2
      name: Pedestrian
    - id: 3
      name: Cyclist
`;
