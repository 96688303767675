import { v4 as uuidv4 } from 'uuid';
import { OBJECT_DETECTION_ENGINE_INFOS } from '../../common/aiEngines';
import { ChoicePropertyDef, TextPropertyDef } from './propertyDef';
class ObjectClassDef {
    constructor(params) {
        this.id = params.id === undefined ? uuidv4() : params.id;
        this.name = params.name;
        this.annotationType = params.annotationType;
        this.color = params.color;
        this.properties = params.properties;
        this.keypointInterfaceId = params.keypointInterfaceId;
        this.minCount = params.minCount;
        this.maxCount = params.maxCount;
        this.aiClassMap = params.aiClassMap;
    }
    static createFromLegacy(labelInterfaceObject, keypointInterfaceId) {
        const info = labelInterfaceObject.info;
        const shape = Object.keys(info.shapes)[0];
        return new ObjectClassDef({
            name: labelInterfaceObject.name,
            annotationType: shape,
            color: info.shapes[shape].default_value.color,
            properties: info.properties.map((property) => property.type === 'objective'
                ? ChoicePropertyDef.createFromLegacy(property)
                : TextPropertyDef.createFromLegacy(property)),
            keypointInterfaceId: keypointInterfaceId,
            minCount: info.min === 0 ? undefined : info.min,
            maxCount: info.max === 0 ? undefined : info.max,
            aiClassMap: 'ai_class_map' in info
                ? info.ai_class_map.map((el) => ({ engineId: el.engine_id, classIds: el.class_ids }))
                : undefined,
        });
    }
    toLegacy() {
        return {
            name: this.name,
            info: Object.assign({ min: this.minCount === undefined ? 0 : this.minCount, max: this.maxCount === undefined ? 0 : this.maxCount, shapes: {
                    [this.annotationType]: {
                        default_value: {
                            color: this.color,
                        },
                    },
                }, properties: this.properties.map(property => property.toLegacy()) }, (this.aiClassMap !== undefined && {
                ai_class_map: this.aiClassMap.map(el => ({
                    engine_id: el.engineId,
                    class_ids: el.classIds,
                })),
            })),
        };
    }
    static listAiEngines() {
        return OBJECT_DETECTION_ENGINE_INFOS;
    }
}
class ObjectGroupDef {
    constructor(params) {
        this.id = params.id === undefined ? uuidv4() : params.id;
        this.name = params.name;
        this.objectClassIds = params.objectClassIds;
    }
    static createFromLegacy(labelInterfaceGroup, objectClasses) {
        const objectNameToId = objectClasses.reduce((idMap, objectClass) => (Object.assign(Object.assign({}, idMap), { [objectClass.name]: objectClass.id })), {});
        return new ObjectGroupDef({
            name: labelInterfaceGroup.name,
            objectClassIds: labelInterfaceGroup.info.classes.map((objectClass) => objectNameToId[objectClass]),
        });
    }
    toLegacy(objectClasses) {
        const objectIdToName = objectClasses.reduce((idMap, objectClass) => (Object.assign(Object.assign({}, idMap), { [objectClass.id]: objectClass.name })), {});
        return {
            name: this.name,
            info: {
                classes: this.objectClassIds.map((objectClass) => objectIdToName[objectClass]),
            },
        };
    }
}
export { ObjectClassDef, ObjectGroupDef };
