var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import _ from 'lodash';
// image
const optionUniqueId = (propertyId, optionId) => {
    return `${propertyId}#${optionId}`;
};
const addClassIdToProp = (prop, object) => {
    return Object.assign(Object.assign({}, prop), { classId: object === null || object === void 0 ? void 0 : object.classId });
};
const flattenObjProps = (objects) => {
    return objects.reduce((acc, obj) => {
        const props = obj === null || obj === void 0 ? void 0 : obj.properties.map(prop => {
            return addClassIdToProp(prop, obj);
        });
        return [...acc, ...props];
    }, []);
};
const getPerFrameProperties = (object, frames) => {
    return frames.reduce((acc, frame) => {
        return [...acc, ..._.map(frame === null || frame === void 0 ? void 0 : frame.properties, property => addClassIdToProp(property, object))];
    }, []);
};
const getPerLabelProperties = (object) => _.map(object === null || object === void 0 ? void 0 : object.properties, property => addClassIdToProp(property, object));
const flattenVideoObjProps = (objects) => {
    return objects.reduce((acc, obj) => {
        return [...acc, ...getPerFrameProperties(obj, obj === null || obj === void 0 ? void 0 : obj.frames), ...getPerLabelProperties(obj)];
    }, []);
};
const flattenCheckboxOptions = (prop) => {
    const { optionNames, optionIds, type } = prop, propInfo = __rest(prop, ["optionNames", "optionIds", "type"]);
    if (!(optionIds && optionIds.length && optionNames && optionNames.length)) {
        return [];
    }
    return optionIds.map((optionId, idx) => {
        return {
            class_id: propInfo === null || propInfo === void 0 ? void 0 : propInfo.classId,
            property_id: propInfo === null || propInfo === void 0 ? void 0 : propInfo.propertyId,
            property_name: propInfo === null || propInfo === void 0 ? void 0 : propInfo.propertyName,
            option_id: optionId,
            option_name: optionNames[idx],
            property_option_id: optionUniqueId(propInfo === null || propInfo === void 0 ? void 0 : propInfo.propertyId, optionId),
            count: 1,
        };
    });
};
const enrichRadioOption = (property) => {
    const { type } = property, propInfo = __rest(property, ["type"]);
    if (_.isNull(property === null || property === void 0 ? void 0 : property.optionId))
        return {};
    return {
        class_id: propInfo === null || propInfo === void 0 ? void 0 : propInfo.classId,
        property_id: propInfo === null || propInfo === void 0 ? void 0 : propInfo.propertyId,
        property_name: propInfo === null || propInfo === void 0 ? void 0 : propInfo.propertyName,
        option_id: propInfo === null || propInfo === void 0 ? void 0 : propInfo.optionId,
        option_name: propInfo === null || propInfo === void 0 ? void 0 : propInfo.optionName,
        property_option_id: optionUniqueId(property === null || property === void 0 ? void 0 : property.propertyId, property.optionId),
        count: 1,
    };
};
const formatProps = (props) => {
    return props.reduce((acc, property) => {
        if ((property === null || property === void 0 ? void 0 : property.type) === 'radio')
            return [...acc, enrichRadioOption(property)];
        else if ((property === null || property === void 0 ? void 0 : property.type) === 'checkbox')
            return [...acc, ...flattenCheckboxOptions(property)];
        // skip free response (not countable)
        return acc;
    }, []);
};
function preprocessObjProps(objects) {
    return formatProps(flattenObjProps(objects));
}
// video
function preprocessVideoObjProps(objects) {
    return formatProps(flattenVideoObjProps(objects));
}
function countProperties(objProps) {
    return _.values(_.map(_.groupBy(objProps, 'property_option_id'), group => {
        return Object.assign(Object.assign({}, group[0]), { count: _.sumBy(group, 'count') });
    }));
}
export { countProperties, flattenVideoObjProps, preprocessObjProps, preprocessVideoObjProps };
