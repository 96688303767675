import { v4 as uuidv4 } from 'uuid';
import { propertyRouter } from './property';
class ObjectTracking {
    constructor(params) {
        this.objects = params.objects;
    }
    static fromJSONObject(obj) {
        return new ObjectTracking({
            objects: obj.map((el) => ObjectInfo.fromJSONObject(el)),
        });
    }
    toJSON() {
        return this.objects;
    }
    getClasses() {
        const count = {};
        for (const obj of this.objects) {
            count[obj.classId] = (count[obj.classId] || 0) + 1;
        }
        return count;
    }
    getAnnos() {
        const count = {};
        for (const obj of this.objects) {
            count[obj.classId] = (count[obj.classId] || 0) + obj.frames.length;
        }
        return count;
    }
}
class ObjectInfo {
    constructor(params) {
        this.id = params.id === undefined ? uuidv4() : params.id;
        this.trackingId = params.trackingId;
        this.classId = params.classId;
        this.className = params.className;
        this.annotationType = params.annotationType;
        this.properties = params.properties;
        this.frames = params.frames;
    }
    static fromJSONObject(obj) {
        return new ObjectInfo({
            id: obj.id,
            trackingId: obj.trackingId,
            classId: obj.classId,
            className: obj.className,
            annotationType: obj.annotationType,
            properties: obj.properties.map((el) => propertyRouter(el).fromJSONObject(el)),
            frames: obj.frames.map((el) => FrameInfo.fromJSONObject(el)),
        });
    }
}
class FrameInfo {
    constructor(params) {
        this.num = params.num;
        this.annotation = params.annotation;
        this.properties = params.properties;
    }
    static fromJSONObject(obj) {
        return new FrameInfo({
            num: obj.num,
            annotation: obj.annotation,
            properties: obj.properties.map((el) => propertyRouter(el).fromJSONObject(el)),
        });
    }
}
export { FrameInfo, ObjectInfo, ObjectTracking };
