import { MultipleChoiceProperty, propertyRouter, SingleChoiceProperty, } from './property';
class Categorization {
    constructor(params) {
        this.properties = params.properties;
        this.frames = params.frames;
    }
    static fromJSONObject(obj) {
        return new Categorization({
            properties: obj.properties.map((el) => propertyRouter(el).fromJSONObject(el)),
            frames: obj.frames.map((el) => ({
                num: el.num,
                properties: el.properties.map((el) => propertyRouter(el).fromJSONObject(el)),
            })),
        });
    }
    getClassesFromProperty(property) {
        if (property instanceof SingleChoiceProperty && property.optionId !== null) {
            return [property.optionId];
        }
        else if (property instanceof MultipleChoiceProperty && property.optionIds !== null) {
            return property.optionIds;
        }
        return [];
    }
    getClasses() {
        const classes = new Set();
        for (const property of this.properties) {
            this.getClassesFromProperty(property).forEach((e) => classes.add(e));
        }
        for (const frame of this.frames) {
            for (const property of frame.properties) {
                this.getClassesFromProperty(property).forEach((e) => classes.add(e));
            }
        }
        return [...classes];
    }
}
export { Categorization };
