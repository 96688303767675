import { KeypointDef } from './keypointDef';
import { ObjectClassDef, ObjectGroupDef } from './objectClassDef';
export class ObjectDetectionDef {
    constructor(params) {
        this.annotationTypes = params.annotationTypes;
        this.objectClasses = params.objectClasses;
        this.objectGroups = params.objectGroups;
        this.keypoints = params.keypoints;
    }
    static fromJSONObject(obj) {
        return new ObjectDetectionDef({
            annotationTypes: obj.annotationTypes,
            objectClasses: obj.objectClasses.map((objectClass) => ObjectClassDef.fromJSONObject(objectClass)),
            objectGroups: obj.objectGroups.map((objectGroup) => ObjectGroupDef.fromJSONObject(objectGroup)),
            keypoints: obj.keypoints.map((keypoint) => KeypointDef.fromJSONObject(keypoint)),
        });
    }
    summarize() {
        const classIdToName = this.objectClasses.reduce((idMap, objectClass) => (Object.assign(Object.assign({}, idMap), { [objectClass.id]: objectClass.name })), {});
        const kpIdToName = this.keypoints.reduce((idMap, keypoint) => (Object.assign(Object.assign({}, idMap), { [keypoint.id]: keypoint.name })), {});
        const classesSummary = this.objectClasses.map(objectClass => (Object.assign({ name: objectClass.name, color: objectClass.color, annotationType: objectClass.annotationType }, (objectClass.keypointInterfaceId !== undefined && {
            keypointName: kpIdToName[objectClass.keypointInterfaceId],
        }))));
        const groupsSummary = this.objectGroups.map(objectGroup => ({
            name: objectGroup.name,
            objectClasses: objectGroup.objectClassIds.map(objectClassId => classIdToName[objectClassId]),
        }));
        return {
            annotationTypes: this.annotationTypes,
            objectClasses: classesSummary,
            objectGroups: groupsSummary,
            keypointInterfaces: this.keypoints.map(keypoint => ({
                name: keypoint.name,
                points: keypoint.points.map((point) => point.name),
            })),
        };
    }
    getClassFromName(name) {
        for (const objectClass of this.objectClasses) {
            if (objectClass.name === name) {
                return objectClass;
            }
        }
        return null;
    }
}
