import { v4 as uuidv4 } from 'uuid';
class CategoryGroup {
    constructor(params) {
        this.id = params.id === undefined ? uuidv4() : params.id;
        this.name = params.name;
        this.children = params.children;
    }
    static createFromLegacy(id, name, children) {
        return new CategoryGroup({ id, name, children });
    }
    toLegacy(parentName) {
        const children = this.children.reduce((acc, child) => acc.concat(child.toLegacy(this.id)), []);
        return children.concat([
            {
                id: this.id,
                name: this.name,
                parent: parentName ? parentName : null,
                children: this.children.map(child => child.id),
                is_group: true,
            },
        ]);
    }
    summarizeToJSON() {
        return {
            name: this.name,
            children: this.children.map(child => child.summarizeToJSON()),
        };
    }
}
class CategoryItem {
    constructor(params) {
        this.id = params.id === undefined ? uuidv4() : params.id;
        this.name = params.name;
    }
    static createFromLegacy(id, name) {
        return new CategoryItem({ id, name });
    }
    toLegacy(parentName) {
        return [
            {
                id: this.id,
                name: this.name,
                parent: parentName ? parentName : null,
                children: [],
                is_group: false,
            },
        ];
    }
    summarizeToJSON() {
        return { name: this.name };
    }
}
export { CategoryGroup, CategoryItem };
