import { VERSION } from '../../common/index';
import { ValidationError } from '../../exceptions';
import { CategorizationDef } from './categorizationDef';
import { ObjectDetectionDef } from './objectDetectionDef';
class LabelResultDef {
    constructor(params) {
        this.version = params.version || VERSION;
        this.type = 'image-siesta';
        this.dataType = params.dataType;
        this.objectDetection = params.objectDetection;
        this.categorization = params.categorization;
    }
    static fromJSONString(jsonStr) {
        try {
            return this.fromJSONObject(JSON.parse(jsonStr));
        }
        catch (e) {
            if (e instanceof ValidationError) {
                throw e;
            }
            throw new ValidationError('LabelResultDef JSON parse error');
        }
    }
    static fromJSONObject(obj) {
        if (obj.type != 'image-siesta') {
            throw new ValidationError('LabelResultDef type error');
        }
        // May check version
        return new LabelResultDef({
            version: obj.version,
            dataType: obj.dataType,
            objectDetection: ObjectDetectionDef.fromJSONObject(obj.objectDetection),
            categorization: CategorizationDef.fromJSONObject(obj.categorization),
        });
    }
    summarize() {
        return {
            dataType: this.dataType,
            objectDetection: this.objectDetection.summarize(),
            imageCategorization: this.categorization.summarize(),
        };
    }
}
export { LabelResultDef };
