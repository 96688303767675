/* eslint-disable @typescript-eslint/no-namespace */
import * as _Common from './common/index';
import * as _Exceptions from './exceptions';
import * as _Image from './image/index';
import * as _ImageV2 from './imageV2/index';
import * as _Pointclouds from './pointclouds/index';
import * as _Video from './video/index';
// Indeed, poor and ugly implementation.
// But have to do this in order to export const and type in namespace...
export var Models;
(function (Models) {
    let Image;
    (function (Image) {
        Image.LabelResult = _Image.LabelResult;
        Image.LabelResultDef = _Image.LabelResultDef;
        Image.ObjectDetectionDef = _Image.ObjectDetectionDef;
        Image.ImageCategorizationDef = _Image.ImageCategorizationDef;
        Image.ObjectClassDef = _Image.ObjectClassDef;
        Image.ObjectGroupDef = _Image.ObjectGroupDef;
        Image.ChoicePropertyDef = _Image.ChoicePropertyDef;
        Image.TextPropertyDef = _Image.TextPropertyDef;
        Image.KeypointDef = _Image.KeypointDef;
        Image.CategoryItem = _Image.CategoryItem;
        Image.CategoryGroup = _Image.CategoryGroup;
    })(Image = Models.Image || (Models.Image = {}));
    let Video;
    (function (Video) {
        Video.LabelResultDef = _Video.LabelResultDef;
        Video.ObjectTrackingDef = _Video.ObjectTrackingDef;
        Video.CategorizationDef = _Video.CategorizationDef;
        Video.ObjectClassDef = _Video.ObjectClassDef;
        Video.ObjectGroupDef = _Video.ObjectGroupDef;
        Video.ChoicePropertyDef = _Video.ChoicePropertyDef;
        Video.TextPropertyDef = _Video.TextPropertyDef;
        Video.KeypointDef = _Video.KeypointDef;
        Video.CategoryItem = _Video.CategoryItem;
        Video.CategoryGroup = _Video.CategoryGroup;
        Video.LabelResult = _Video.LabelResult;
        Video.ObjectTracking = _Video.ObjectTracking;
        Video.Categorization = _Video.Categorization;
        Video.ObjectInfo = _Video.ObjectInfo;
        Video.FrameInfo = _Video.FrameInfo;
        Video.SingleChoiceProperty = _Video.SingleChoiceProperty;
        Video.MultipleChoiceProperty = _Video.MultipleChoiceProperty;
        Video.TextProperty = _Video.TextProperty;
    })(Video = Models.Video || (Models.Video = {}));
    let ImageV2;
    (function (ImageV2) {
        ImageV2.LabelResultDef = _ImageV2.LabelResultDef;
        ImageV2.ObjectDetectionDef = _ImageV2.ObjectDetectionDef;
        ImageV2.CategorizationDef = _ImageV2.CategorizationDef;
        ImageV2.ObjectClassDef = _ImageV2.ObjectClassDef;
        ImageV2.ObjectGroupDef = _ImageV2.ObjectGroupDef;
        ImageV2.ChoicePropertyDef = _ImageV2.ChoicePropertyDef;
        ImageV2.TextPropertyDef = _ImageV2.TextPropertyDef;
        ImageV2.KeypointDef = _ImageV2.KeypointDef;
        ImageV2.CategoryItem = _ImageV2.CategoryItem;
        ImageV2.CategoryGroup = _ImageV2.CategoryGroup;
        ImageV2.LabelResult = _ImageV2.LabelResult;
        ImageV2.ObjectDetection = _ImageV2.ObjectDetection;
        ImageV2.Categorization = _ImageV2.Categorization;
        ImageV2.ObjectInfo = _ImageV2.ObjectInfo;
        ImageV2.SingleChoiceProperty = _ImageV2.SingleChoiceProperty;
        ImageV2.MultipleChoiceProperty = _ImageV2.MultipleChoiceProperty;
        ImageV2.TextProperty = _ImageV2.TextProperty;
    })(ImageV2 = Models.ImageV2 || (Models.ImageV2 = {}));
    let Pointclouds;
    (function (Pointclouds) {
        Pointclouds.LabelResultDef = _Pointclouds.LabelResultDef;
        Pointclouds.ObjectTrackingDef = _Pointclouds.ObjectTrackingDef;
        Pointclouds.CategorizationDef = _Pointclouds.CategorizationDef;
        Pointclouds.ObjectClassDef = _Pointclouds.ObjectClassDef;
        Pointclouds.ObjectGroupDef = _Pointclouds.ObjectGroupDef;
        Pointclouds.ChoicePropertyDef = _Pointclouds.ChoicePropertyDef;
        Pointclouds.TextPropertyDef = _Pointclouds.TextPropertyDef;
        Pointclouds.CategoryItem = _Pointclouds.CategoryItem;
        Pointclouds.CategoryGroup = _Pointclouds.CategoryGroup;
        Pointclouds.LabelResult = _Pointclouds.LabelResult;
        Pointclouds.ObjectTracking = _Pointclouds.ObjectTracking;
        Pointclouds.Categorization = _Pointclouds.Categorization;
        Pointclouds.ObjectInfo = _Pointclouds.ObjectInfo;
        Pointclouds.FrameInfo = _Pointclouds.FrameInfo;
        Pointclouds.SingleChoiceProperty = _Pointclouds.SingleChoiceProperty;
        Pointclouds.MultipleChoiceProperty = _Pointclouds.MultipleChoiceProperty;
        Pointclouds.TextProperty = _Pointclouds.TextProperty;
    })(Pointclouds = Models.Pointclouds || (Models.Pointclouds = {}));
})(Models || (Models = {}));
export var Exceptions;
(function (Exceptions) {
    Exceptions.ValidationError = _Exceptions.ValidationError;
})(Exceptions || (Exceptions = {}));
export var Common;
(function (Common) {
    Common.DEFAULT_COLOR_PALETTE = _Common.DEFAULT_COLOR_PALETTE;
    Common.OBJECT_DETECTION_ENGINE_INFOS = _Common.OBJECT_DETECTION_ENGINE_INFOS;
    Common.POINTCLOUDS_TRACKING_ENGINE_INFOS = _Common.POINTCLOUDS_TRACKING_ENGINE_INFOS;
})(Common || (Common = {}));
