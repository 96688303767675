const facialKeypointsTemplateArgs = {
    id: 'facial-landmark-15',
    name: 'face',
    points: [
        {
            name: 'left eye center',
            color: '#64b5f6',
            symmetricIdx: 3,
            defaultValue: {
                state: { visible: true },
                x: 0.75,
                y: 0.25,
            },
        },
        {
            name: 'left eye inner corner',
            color: '#64b5f6',
            symmetricIdx: 4,
            defaultValue: {
                state: { visible: true },
                x: 0.625,
                y: 0.25,
            },
        },
        {
            name: 'left eye outer corner',
            color: '#64b5f6',
            symmetricIdx: 5,
            defaultValue: {
                state: { visible: true },
                x: 0.875,
                y: 0.25,
            },
        },
        {
            name: 'right eye center',
            color: '#ff8a65',
            symmetricIdx: 0,
            defaultValue: {
                state: { visible: true },
                x: 0.25,
                y: 0.25,
            },
        },
        {
            name: 'right eye inner corner',
            color: '#ff8a65',
            symmetricIdx: 1,
            defaultValue: {
                state: { visible: true },
                x: 0.375,
                y: 0.25,
            },
        },
        {
            name: 'right eye outer corner',
            color: '#ff8a65',
            symmetricIdx: 2,
            defaultValue: {
                state: { visible: true },
                x: 0.125,
                y: 0.25,
            },
        },
        {
            name: 'left eyebrow inner end',
            color: '#64b5f6',
            symmetricIdx: 8,
            defaultValue: {
                state: { visible: true },
                x: 0.625,
                y: 0,
            },
        },
        {
            name: 'left eyebrow outer end',
            color: '#64b5f6',
            symmetricIdx: 9,
            defaultValue: {
                state: { visible: true },
                x: 1,
                y: 0,
            },
        },
        {
            name: 'right eyebrow inner end',
            color: '#ff8a65',
            symmetricIdx: 6,
            defaultValue: {
                state: { visible: true },
                x: 0.375,
                y: 0,
            },
        },
        {
            name: 'right eyebrow outer end',
            color: '#ff8a65',
            symmetricIdx: 7,
            defaultValue: {
                state: { visible: true },
                x: 0,
                y: 0,
            },
        },
        {
            name: 'nose tip',
            color: '#d50000',
            defaultValue: {
                state: { visible: true },
                x: 0.5,
                y: 0.5,
            },
        },
        {
            name: 'mouth left corner',
            color: '#64b5f6',
            symmetricIdx: 12,
            defaultValue: {
                state: { visible: true },
                x: 0.75,
                y: 0.875,
            },
        },
        {
            name: 'mouth right corner',
            color: '#ff8a65',
            symmetricIdx: 11,
            defaultValue: {
                state: { visible: true },
                x: 0.25,
                y: 0.875,
            },
        },
        {
            name: 'mouth center top lip',
            color: '#d50000',
            symmetricIdx: 13,
            defaultValue: {
                state: { visible: true },
                x: 0.5,
                y: 0.75,
            },
        },
        {
            name: 'mouth center bottom lip',
            color: '#d50000',
            symmetricIdx: 14,
            defaultValue: {
                state: { visible: true },
                x: 0.5,
                y: 1,
            },
        },
    ],
    edges: [
        {
            u: 1,
            v: 0,
            color: '#64b5f6',
        },
        {
            u: 2,
            v: 0,
            color: '#64b5f6',
        },
        {
            u: 4,
            v: 3,
            color: '#ff8a65',
        },
        {
            u: 5,
            v: 3,
            color: '#ff8a65',
        },
        {
            u: 7,
            v: 6,
            color: '#64b5f6',
        },
        {
            u: 9,
            v: 8,
            color: '#ff8a65',
        },
        {
            u: 13,
            v: 11,
            color: '#64b5f6',
        },
        {
            u: 14,
            v: 11,
            color: '#64b5f6',
        },
        {
            u: 13,
            v: 12,
            color: '#ff8a65',
        },
        {
            u: 14,
            v: 12,
            color: '#ff8a65',
        },
    ],
    allowValidInvisibles: false,
};
const humanPoseTemplateArgs = {
    id: 'human-pose-17',
    name: 'human',
    points: [
        {
            name: 'nose',
            color: '#d50000',
            defaultValue: {
                state: { visible: true },
                x: 0.5,
                y: 0.1,
            },
        },
        {
            name: 'left eye',
            color: '#64b5f6',
            symmetricIdx: 2,
            defaultValue: {
                state: { visible: true },
                x: 0.55,
                y: 0.05,
            },
        },
        {
            name: 'right eye',
            color: '#ff8a65',
            symmetricIdx: 1,
            defaultValue: {
                state: { visible: true },
                x: 0.45,
                y: 0.05,
            },
        },
        {
            name: 'left ear',
            color: '#64b5f6',
            symmetricIdx: 4,
            defaultValue: {
                state: { visible: true },
                x: 0.6,
                y: 0.075,
            },
        },
        {
            name: 'right ear',
            color: '#ff8a65',
            symmetricIdx: 3,
            defaultValue: {
                state: { visible: true },
                x: 0.4,
                y: 0.075,
            },
        },
        {
            name: 'left shoulder',
            color: '#64b5f6',
            symmetricIdx: 6,
            defaultValue: {
                state: { visible: true },
                x: 0.65,
                y: 0.2,
            },
        },
        {
            name: 'right shoulder',
            color: '#ff8a65',
            symmetricIdx: 5,
            defaultValue: {
                state: { visible: true },
                x: 0.35,
                y: 0.2,
            },
        },
        {
            name: 'left elbow',
            color: '#64b5f6',
            symmetricIdx: 8,
            defaultValue: {
                state: { visible: true },
                x: 0.85,
                y: 0.3,
            },
        },
        {
            name: 'right elbow',
            color: '#ff8a65',
            symmetricIdx: 7,
            defaultValue: {
                state: { visible: true },
                x: 0.15,
                y: 0.3,
            },
        },
        {
            name: 'left wrist',
            color: '#64b5f6',
            symmetricIdx: 10,
            defaultValue: {
                state: { visible: true },
                x: 0.75,
                y: 0.45,
            },
        },
        {
            name: 'right wrist',
            color: '#ff8a65',
            symmetricIdx: 9,
            defaultValue: {
                state: { visible: true },
                x: 0.25,
                y: 0.45,
            },
        },
        {
            name: 'left hip',
            color: '#64b5f6',
            symmetricIdx: 12,
            defaultValue: {
                state: { visible: true },
                x: 0.62,
                y: 0.5,
            },
        },
        {
            name: 'right hip',
            color: '#ff8a65',
            symmetricIdx: 11,
            defaultValue: {
                state: { visible: true },
                x: 0.38,
                y: 0.5,
            },
        },
        {
            name: 'left knee',
            color: '#64b5f6',
            symmetricIdx: 14,
            defaultValue: {
                state: { visible: true },
                x: 0.6,
                y: 0.7,
            },
        },
        {
            name: 'right_knee',
            color: '#ff8a65',
            symmetricIdx: 13,
            defaultValue: {
                state: { visible: true },
                x: 0.4,
                y: 0.7,
            },
        },
        {
            name: 'left ankle',
            color: '#64b5f6',
            symmetricIdx: 16,
            defaultValue: {
                state: { visible: true },
                x: 0.6,
                y: 0.9,
            },
        },
        {
            name: 'right ankle',
            color: '#ff8a65',
            symmetricIdx: 15,
            defaultValue: {
                state: { visible: true },
                x: 0.4,
                y: 0.9,
            },
        },
    ],
    edges: [
        {
            u: 2,
            v: 4,
            color: '#ff8a65',
        },
        {
            u: 0,
            v: 2,
            color: '#ff8a65',
        },
        {
            u: 8,
            v: 6,
            color: '#ff8a65',
        },
        {
            u: 10,
            v: 8,
            color: '#ff8a65',
        },
        {
            u: 12,
            v: 6,
            color: '#ff8a65',
        },
        {
            u: 14,
            v: 12,
            color: '#ff8a65',
        },
        {
            u: 16,
            v: 14,
            color: '#ff8a65',
        },
        {
            u: 1,
            v: 2,
            color: '#4db6ac',
        },
        {
            u: 5,
            v: 6,
            color: '#4db6ac',
        },
        {
            u: 11,
            v: 12,
            color: '#4db6ac',
        },
        {
            u: 1,
            v: 0,
            color: '#64b5f6',
        },
        {
            u: 3,
            v: 1,
            color: '#64b5f6',
        },
        {
            u: 7,
            v: 5,
            color: '#64b5f6',
        },
        {
            u: 9,
            v: 7,
            color: '#64b5f6',
        },
        {
            u: 11,
            v: 5,
            color: '#64b5f6',
        },
        {
            u: 13,
            v: 11,
            color: '#64b5f6',
        },
        {
            u: 15,
            v: 13,
            color: '#64b5f6',
        },
    ],
    allowValidInvisibles: false,
};
export { facialKeypointsTemplateArgs, humanPoseTemplateArgs };
