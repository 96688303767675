import { MultipleChoiceProperty, propertyRouter, SingleChoiceProperty, TextProperty, } from './property';
class Categorization {
    constructor(params) {
        this.properties = params.properties;
    }
    static fromJSONObject(obj) {
        return new Categorization({
            properties: obj.properties.map((property) => propertyRouter(property).fromJSONObject(property)),
        });
    }
    getClasses() {
        const classes = [];
        for (const obj of this.properties) {
            if (obj instanceof TextProperty)
                continue;
            if (obj instanceof SingleChoiceProperty && obj.optionId !== null) {
                classes.push(obj.optionId);
            }
            else if (obj instanceof MultipleChoiceProperty && obj.optionIds !== null) {
                classes.push(...obj.optionIds);
            }
        }
        return classes;
    }
}
export { Categorization };
