import { v4 as uuidv4 } from 'uuid';
import { facialKeypointTemplateArgs, humanPoseTemplateArgs } from '../fixtures/keypointTemplates';
class KeypointDef {
    constructor(params) {
        this.id = params.id === undefined ? uuidv4() : params.id;
        this.name = params.name;
        this.points = params.points;
        this.edges = params.edges;
        this.allowValidInvisibles = params.allowValidInvisibles;
        this.symmetry = params.symmetry;
        this.defaultPoints = params.defaultPoints;
    }
    static createFromLegacy(keypointInterface) {
        function defaultFromLegacy(nodes, pointVisibility) {
            // build symmetry (opposite in legacy)
            const symmetry = [];
            for (let idx = 0; idx < nodes.length; ++idx)
                symmetry.push(idx);
            for (let idx = 0; idx < nodes.length; ++idx) {
                if ('opposite' in nodes[idx]) {
                    symmetry[idx] = nodes[idx].opposite;
                    symmetry[nodes[idx].opposite] = idx;
                }
            }
            let defaultPoints, allowValidInvisibles;
            if (pointVisibility.length == 2) {
                // 2-state
                defaultPoints = nodes.map((node) => node.v != 0
                    ? { state: { visible: true }, x: node.x, y: node.y }
                    : { state: { visible: false, valid: false }, x: node.x, y: node.y });
                allowValidInvisibles = false;
            }
            else {
                defaultPoints = nodes.map((node) => node.v != 0
                    ? node.v == 1
                        ? { state: { visible: false, valid: true }, x: node.x, y: node.y }
                        : { state: { visible: true }, x: node.x, y: node.y }
                    : { state: { visible: false, valid: false }, x: node.x, y: node.y });
                allowValidInvisibles = true;
            }
            return {
                defaultPoints,
                allowValidInvisibles,
                symmetry,
            };
        }
        const name = keypointInterface.name;
        const points = keypointInterface.nodes.map((node) => ({
            name: node.name,
            color: node.color,
        }));
        const edges = keypointInterface.edges.map((edge) => ({
            color: edge.color,
            u: edge.to,
            v: edge.from,
        }));
        const { defaultPoints, allowValidInvisibles, symmetry } = defaultFromLegacy(keypointInterface.nodes, keypointInterface.point_visibility);
        return new KeypointDef({
            name,
            points,
            edges,
            allowValidInvisibles,
            symmetry,
            defaultPoints,
        });
    }
    toLegacy() {
        const nodes = [];
        for (let idx = 0; idx < this.points.length; ++idx) {
            const node = {
                name: this.points[idx].name,
                color: this.points[idx].color,
            };
            if (this.symmetry[idx] > idx) {
                node.opposite = this.symmetry[idx];
            }
            const defaultPoint = this.defaultPoints[idx];
            node.x = defaultPoint.x;
            node.y = defaultPoint.y;
            if (this.allowValidInvisibles) {
                if (defaultPoint.state.visible)
                    node.v = 2;
                else if (defaultPoint.state.valid)
                    node.v = 1;
                else
                    node.v = 0;
            }
            else {
                if (defaultPoint.state.visible)
                    node.v = 1;
                else
                    node.v = 0;
            }
            nodes.push(node);
        }
        return {
            id: 'keypoint-id',
            name: this.name,
            nodes: nodes,
            edges: this.edges.map((edge) => ({
                color: edge.color,
                to: edge.u,
                from: edge.v,
            })),
            point_visibility: this.allowValidInvisibles
                ? [
                    { name: 'not labeled', value: 0 },
                    { name: 'labeled but not visible', value: 1 },
                    { name: 'labeled and visible', value: 2 },
                ]
                : [
                    { name: 'not labeled', value: 0 },
                    { name: 'labeled and visible', value: 1 },
                ],
        };
    }
    static listTemplates() {
        return [
            {
                name: 'Face',
                img: 'https://asset.superb-ai.com/assets/img/keypoint_saba/facial_keypoints_template_15.png',
                keypointDef: new KeypointDef(facialKeypointTemplateArgs),
            },
            {
                name: 'Human',
                img: 'https://asset.superb-ai.com/assets/img/keypoint_saba/human_pose_template_17.png',
                keypointDef: new KeypointDef(humanPoseTemplateArgs),
            },
        ];
    }
}
export { KeypointDef };
