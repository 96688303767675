import { propertyDefRouter } from './propertyDef';
class CategorizationDef {
    constructor(params) {
        this.properties = params.properties;
    }
    summarize() {
        return this.properties.map(property => property.summarize());
    }
    static fromJSONObject(obj) {
        return new CategorizationDef({
            properties: obj.properties.map((property) => propertyDefRouter(property).fromJSONObject(property)),
        });
    }
}
export { CategorizationDef };
