import { toCamelCaseKeys, toSnakeCaseKeys } from '../../common/stringUtils';
import { ValidationError } from '../../exceptions';
import { Categorization } from './categorization';
import { ObjectTracking } from './objectTracking';
class LabelResult {
    constructor(params) {
        this.labelResultDef = params.labelResultDef;
        this.imageInfo = params.imageInfo;
        this.editInfo = params.editInfo;
        this.objectTracking = params.objectTracking;
        this.categorization = params.categorization;
    }
    toJSON() {
        const obj = {
            version: this.labelResultDef.version,
            meta: {
                imageInfo: this.imageInfo,
                editInfo: this.editInfo,
            },
            result: {
                objects: this.objectTracking.toJSON(),
                categories: this.categorization,
            },
            tags: this.generateLabelTags(),
        };
        return toSnakeCaseKeys(obj);
    }
    static fromJSONString(jsonStr, labelResultDef) {
        try {
            return this.fromJSONObject(JSON.parse(jsonStr), labelResultDef);
        }
        catch (e) {
            if (e instanceof ValidationError) {
                throw e;
            }
            throw new ValidationError('LabelResult JSON parse error');
        }
    }
    static fromJSONObject(obj, labelResultDef) {
        obj = toCamelCaseKeys(obj);
        return new LabelResult({
            labelResultDef: labelResultDef,
            imageInfo: obj.meta.imageInfo,
            editInfo: obj.meta.editInfo,
            objectTracking: ObjectTracking.fromJSONObject(obj.result.objects),
            categorization: Categorization.fromJSONObject(obj.result.categories),
        });
    }
    generateLabelTags() {
        const objectClassesCount = this.objectTracking.getClasses();
        const objectClassesId = Object.keys(objectClassesCount);
        const annosCount = this.objectTracking.getAnnos();
        const objectClassIdToName = this.labelResultDef.objectTracking.objectClasses.reduce((idMap, objectClass) => (Object.assign(Object.assign({}, idMap), { [objectClass.id]: objectClass.name })), {});
        const objectClassesName = objectClassesId.map((classId) => objectClassIdToName[classId]);
        const categoriesId = this.categorization.getClasses();
        return Object.assign({ classes_id: objectClassesId, categories_id: categoriesId, class: objectClassesName.concat(categoriesId), classes_count: objectClassesId.map((classId) => ({
                id: classId,
                name: objectClassIdToName[classId],
                count: objectClassesCount[classId],
            })), classes_annotation_count: objectClassesId.map((classId) => ({
                id: classId,
                name: objectClassIdToName[classId],
                count: annosCount[classId],
            })) }, (this.editInfo.elapsedTime !== undefined && { time_spent: this.editInfo.elapsedTime }));
    }
}
export { LabelResult };
