const DEFAULT_COLOR_PALETTE = [
    '#FF625A',
    '#F89400',
    '#FFCC00',
    '#A3EB57',
    '#4AE2B9',
    '#5A7BEF',
    '#6648FF',
    '#A6A6A6',
];
export { DEFAULT_COLOR_PALETTE };
