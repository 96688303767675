import { KeypointDef } from './keypointDef';
import { ObjectClassDef, ObjectGroupDef } from './objectClassDef';
export class ObjectDetectionDef {
    constructor(params) {
        this.annotationTypes = params.annotationTypes;
        this.objectClasses = params.objectClasses;
        this.objectGroups = params.objectGroups;
        this.keypointDefs = params.keypointDefs;
    }
    static createFromLegacy(objects, groups, keypointInterfaces) {
        const keypointDefs = keypointInterfaces === undefined || keypointInterfaces.length == 0
            ? []
            : [KeypointDef.createFromLegacy(keypointInterfaces[0])];
        const objectClasses = objects.map(objectClass => ObjectClassDef.createFromLegacy(objectClass, keypointDefs.length === 0 ? undefined : keypointDefs[0].id));
        const objectGroups = groups.map(objectGroup => ObjectGroupDef.createFromLegacy(objectGroup, objectClasses));
        const duplicatedAnnotationTypes = objectClasses.map(objectClass => objectClass.annotationType);
        const annotationTypes = duplicatedAnnotationTypes.filter((v, i, self) => {
            return self.indexOf(v) === i;
        }); // distinct
        return new ObjectDetectionDef({
            annotationTypes,
            objectClasses,
            objectGroups,
            keypointDefs,
        });
    }
    toLegacy() {
        const result = {};
        // Object Detection is required
        result.objects = this.objectClasses.map(objectClass => objectClass.toLegacy());
        result.groups = this.objectGroups.map(objectGroup => objectGroup.toLegacy(this.objectClasses));
        // keypoint_interface key is optional
        if (this.keypointDefs.length > 0) {
            result.keypoint_interface = this.keypointDefs.map(keypointDef => keypointDef.toLegacy());
        }
        return result;
    }
}
