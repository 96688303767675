import { propertyDefRouter, } from './propertyDef';
class CategorizationDef {
    constructor(params) {
        this.properties = params.properties;
    }
    summarize() {
        return this.properties.map(properties => properties.summarize());
    }
    static fromJSONObject(obj) {
        return new CategorizationDef({
            properties: obj.properties.map((el) => propertyDefRouter(el).fromJSONObject(el)),
        });
    }
}
export { CategorizationDef };
