import _ from 'lodash';
function toCamelCaseKeys(node) {
    if (node instanceof Array) {
        return _.map(node, toCamelCaseKeys);
    }
    if (node instanceof Object) {
        return _.mapValues(_.mapKeys(node, (_n, k) => _.camelCase(k)), toCamelCaseKeys);
    }
    return node;
}
const snakeCase = (str) => {
    return str
        .replace(/\W+/g, ' ')
        .split(/ |\B(?=[A-Z])/)
        .map(word => word.toLowerCase())
        .join('_');
};
function toSnakeCaseKeys(node) {
    if (node instanceof Array) {
        return _.map(node, toSnakeCaseKeys);
    }
    if (node instanceof Object) {
        // Changed lodash snakeCase to string utils snakeCase function. May have side effects.
        return _.mapValues(_.mapKeys(node, (_, k) => snakeCase(k)), toSnakeCaseKeys);
    }
    return node;
}
export { toCamelCaseKeys, toSnakeCaseKeys };
