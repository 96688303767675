import { v4 as uuidv4 } from 'uuid';
class CategoryGroup {
    constructor(params) {
        this.id = params.id === undefined ? uuidv4() : params.id;
        this.name = params.name;
        this.children = params.children;
    }
    summarize() {
        return {
            name: this.name,
            children: this.children.map(child => child.summarize()),
        };
    }
    static fromJSONObject(obj) {
        return new CategoryGroup({
            id: obj.id,
            name: obj.name,
            children: obj.children.map((el) => el.children === undefined
                ? CategoryItem.fromJSONObject(el)
                : CategoryGroup.fromJSONObject(el)),
        });
    }
}
class CategoryItem {
    constructor(params) {
        this.id = params.id === undefined ? uuidv4() : params.id;
        this.name = params.name;
    }
    summarize() {
        return { name: this.name };
    }
    static fromJSONObject(obj) {
        return new CategoryItem({
            id: obj.id,
            name: obj.name,
        });
    }
}
export { CategoryGroup, CategoryItem };
