import semver from 'semver';
import { toCamelCaseKeys, toSnakeCaseKeys } from '../../common/stringUtils';
import { ValidationError } from '../../exceptions';
import { Categorization } from './categorization';
import { ObjectDetection } from './objectDetection';
class LabelResult {
    constructor(params) {
        this.labelResultDef = params.labelResultDef;
        this.imageInfo = params.imageInfo;
        this.editInfo = params.editInfo;
        this.objectDetection = params.objectDetection;
        this.categorization = params.categorization;
    }
    toJSON() {
        let obj = {
            version: this.labelResultDef.version,
            meta: {
                imageInfo: this.imageInfo,
                editInfo: this.editInfo,
            },
            result: {
                objects: this.objectDetection.toJSON(),
                categories: this.categorization,
            },
            tags: this.generateLabelTags(),
        };
        if (!semver.lt(this.labelResultDef.version, '0.4.0')) {
            // convert snake to camel
            obj = toSnakeCaseKeys(obj);
        }
        return obj;
    }
    static fromJSONString(jsonStr, labelResultDef) {
        try {
            return this.fromJSONObject(JSON.parse(jsonStr), labelResultDef);
        }
        catch (e) {
            if (e instanceof ValidationError) {
                throw e;
            }
            throw new ValidationError('LabelResult JSON parse error');
        }
    }
    static fromJSONObject(obj, labelResultDef) {
        if (!semver.lt(labelResultDef.version, '0.4.0')) {
            // convert snake to camel
            obj = toCamelCaseKeys(obj);
        }
        return new LabelResult({
            labelResultDef: labelResultDef,
            imageInfo: obj.meta.imageInfo,
            editInfo: obj.meta.editInfo,
            objectDetection: ObjectDetection.fromJSONObject(obj.result.objects),
            categorization: Categorization.fromJSONObject(obj.result.categories),
        });
    }
    generateLabelTags() {
        const objectClassesCount = this.objectDetection.getClasses();
        const objectClassesId = Object.keys(objectClassesCount);
        const objectClassIdToName = this.labelResultDef.objectDetection.objectClasses.reduce((idMap, objectClass) => (Object.assign(Object.assign({}, idMap), { [objectClass.id]: objectClass.name })), {});
        const objectClassesName = objectClassesId.map((classId) => objectClassIdToName[classId]);
        const categoriesId = this.categorization.getClasses();
        return Object.assign({ classes_id: objectClassesId, categories_id: categoriesId, class: objectClassesName.concat(categoriesId), classes_count: objectClassesId.map((classId) => ({
                id: classId,
                name: objectClassIdToName[classId],
                count: objectClassesCount[classId],
            })), classes_properties_count: this.objectDetection.getClassProperties() }, (this.editInfo.elapsedTime !== undefined && { time_spent: this.editInfo.elapsedTime }));
    }
}
export { LabelResult };
