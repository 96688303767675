import { v4 as uuidv4 } from 'uuid';
import { countProperties, preprocessObjProps } from '../../common/objectUtils';
import { propertyRouter } from './property';
class ObjectDetection {
    constructor(params) {
        this.objects = params.objects;
    }
    static fromJSONObject(obj) {
        return new ObjectDetection({
            objects: obj.map((object) => ObjectInfo.fromJSONObject(object)),
        });
    }
    toJSON() {
        return this.objects;
    }
    getClasses() {
        const count = {};
        for (const obj of this.objects) {
            count[obj.classId] = (count[obj.classId] || 0) + 1;
        }
        return count;
    }
    getClassProperties() {
        const objProps = preprocessObjProps(this.objects);
        return countProperties(objProps);
    }
}
class ObjectInfo {
    constructor(params) {
        this.id = params.id === undefined ? uuidv4() : params.id;
        this.trackingId = params.trackingId;
        this.classId = params.classId;
        this.className = params.className;
        this.annotationType = params.annotationType;
        this.annotation = params.annotation;
        this.properties = params.properties;
    }
    static fromJSONObject(obj) {
        return new ObjectInfo({
            id: obj.id,
            trackingId: obj.trackingId,
            classId: obj.classId,
            className: obj.className,
            annotationType: obj.annotationType,
            annotation: obj.annotation,
            properties: obj.properties.map((el) => propertyRouter(el).fromJSONObject(el)),
        });
    }
}
export { ObjectDetection, ObjectInfo };
