export default `
- id: co_20200526
  name: Common Objects AI
  classes:
    - id: 1
      name: person
    - id: 2
      name: vehicle
      children:
        - id: 3
          name: land vehicle
          children:
            - id: 4
              name: bicycle
            - id: 5
              name: motorcycle
            - id: 6
              name: car
            - id: 7
              name: bus
            - id: 8
              name: train
            - id: 9
              name: truck
        - id: 11
          name: airplane
        - id: 14
          name: boat
    - id: 16
      name: outdoor
      children:
        - id: 17
          name: traffic light
        - id: 18
          name: fire hydrant
        - id: 19
          name: stop sign
        - id: 20
          name: parking meter
        - id: 21
          name: bench
    - id: 22
      name: animal
      children:
        - id: 23
          name: bird
        - id: 24
          name: cat
        - id: 25
          name: dog
        - id: 26
          name: horse
        - id: 27
          name: sheep
        - id: 28
          name: cow
        - id: 29
          name: elephant
        - id: 30
          name: bear
        - id: 31
          name: zebra
        - id: 32
          name: giraffe
    - id: 35
      name: accessory
      children:
        - id: 39
          name: luggage
          children:
            - id: 40
              name: backpack
            - id: 41
              name: handbag
            - id: 42
              name: suitcase
        - id: 43
          name: umbrella
        - id: 51
          name: tie
    - id: 53
      name: sports
      children:
        - id: 54
          name: frisbee
        - id: 55
          name: skis
        - id: 56
          name: snowboard
        - id: 57
          name: sports ball
        - id: 58
          name: kite
        - id: 59
          name: baseball bat
        - id: 60
          name: baseball glove
        - id: 61
          name: skateboard
        - id: 62
          name: surfboard
        - id: 64
          name: tennis racket
    - id: 65
      name: kitchen
      children:
        - id: 66
          name: bottle
        - id: 67
          name: wine glass
        - id: 118
          name: cup
        - id: 68
          name: fork
        - id: 69
          name: knife
        - id: 70
          name: spoon
        - id: 71
          name: bowl
    - id: 72
      name: food
      children:
        - id: 73
          name: banana
        - id: 74
          name: apple
        - id: 75
          name: sandwich
        - id: 76
          name: orange
        - id: 77
          name: broccoli
        - id: 78
          name: carrot
        - id: 79
          name: hot dog
        - id: 80
          name: pizza
        - id: 81
          name: donut
        - id: 82
          name: cake
    - id: 83
      name: furniture
      children:
        - id: 84
          name: chair
        - id: 85
          name: couch
        - id: 86
          name: potted plant
        - id: 90
          name: bed
        - id: 92
          name: dining table
        - id: 94
          name: toilet
    - id: 96
      name: electronic
      children:
        - id: 97
          name: tv
        - id: 98
          name: laptop
        - id: 99
          name: mouse
        - id: 100
          name: remote
        - id: 101
          name: keyboard
        - id: 102
          name: cell phone
    - id: 103
      name: appliance
      children:
        - id: 104
          name: microwave
        - id: 105
          name: oven
        - id: 106
          name: toaster
        - id: 107
          name: sink
        - id: 108
          name: refrigerator
    - id: 110
      name: indoor
      children:
        - id: 111
          name: book
        - id: 112
          name: clock
        - id: 113
          name: vase
        - id: 114
          name: scissors
        - id: 115
          name: teddy bear
        - id: 116
          name: hair drier
        - id: 117
          name: toothbrush
`;
