import { CategoryGroup, CategoryItem } from './categoryTree';
import { ChoicePropertyDef } from './propertyDef';
class ImageCategorizationDef {
    constructor(params) {
        this.tasks = params.tasks;
    }
    static createFromLegacy(categorization) {
        if (categorization === undefined)
            return new ImageCategorizationDef({ tasks: [] });
        // legacy supports only single categorization task
        const categoryNodeDict = {};
        const wordMap = categorization.word_map;
        while (wordMap.length - 1 > Object.keys(categoryNodeDict).length) {
            for (const word of wordMap) {
                if (word.id === 'root')
                    continue;
                if (word.id in categoryNodeDict)
                    continue;
                const childrenAlive = word.children.filter((child) => !(child in categoryNodeDict)).length;
                if (childrenAlive === 0) {
                    categoryNodeDict[word.id] = word.is_group
                        ? CategoryGroup.createFromLegacy(word.id, word.name, word.children.map((child) => categoryNodeDict[child]))
                        : CategoryItem.createFromLegacy(word.id, word.name);
                }
            }
        }
        const rootItem = wordMap.find((word) => word.id === 'root');
        // TODO: Check whether tree is valid (connectedness, ...)
        return new ImageCategorizationDef({
            tasks: [
                new ChoicePropertyDef({
                    name: 'Image Categorization',
                    type: 'Multiple Selection',
                    defaultValue: [],
                    required: true,
                    options: rootItem.children.map((child) => categoryNodeDict[child]),
                }),
            ],
        });
    }
    toLegacy() {
        if (this.tasks.length === 0)
            return {};
        const task = this.tasks[0];
        if (!(task instanceof ChoicePropertyDef))
            return {};
        return {
            categorization: {
                word_map: task.options
                    .reduce((acc, option) => acc.concat(option.toLegacy('root')), [])
                    .concat([
                    {
                        id: 'root',
                        name: 'Root',
                        parent: null,
                        children: task.options.map(option => option.id),
                        is_group: true,
                    },
                ]),
            },
        };
    }
}
export { ImageCategorizationDef };
