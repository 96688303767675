import { v4 as uuidv4 } from 'uuid';
import { facialKeypointsTemplateArgs, humanPoseTemplateArgs } from '../fixtures/keypointTemplates';
class KeypointDef {
    constructor(params) {
        this.id = params.id === undefined ? uuidv4() : params.id;
        this.name = params.name;
        this.points = params.points;
        this.edges = params.edges;
        this.allowValidInvisibles = params.allowValidInvisibles;
    }
    static fromJSONObject(obj) {
        return new KeypointDef({
            id: obj.id,
            name: obj.name,
            points: obj.points,
            edges: obj.edges,
            allowValidInvisibles: obj.allowValidInvisibles,
        });
    }
    static listTemplates() {
        return [new KeypointDef(facialKeypointsTemplateArgs), new KeypointDef(humanPoseTemplateArgs)];
    }
}
export { KeypointDef };
