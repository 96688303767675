import { ValidationError } from '../../exceptions';
import { ImageCategorizationDef } from './imageCategorizationDef';
import { ObjectDetectionDef } from './objectDetectionDef';
class LabelResultDef {
    constructor(params) {
        this.type = params.type;
        this.dataType = params.dataType;
        this.objectDetection = params.objectDetection;
        this.imageCategorization = params.imageCategorization;
    }
    /**
     * Convert raw label interface JSON to LabelResultDef instance
     * TODO: consider more than legacy type, should we propagate type into subinstances
     * @param labelInterface Raw label interface JSON
     * @returns LabelResultDef instance
     */
    static createFromLegacy(labelInterface) {
        try {
            return new LabelResultDef({
                type: 'legacyImage',
                dataType: 'image',
                objectDetection: ObjectDetectionDef.createFromLegacy(labelInterface.objects, labelInterface.groups, labelInterface.keypoint_interface),
                imageCategorization: ImageCategorizationDef.createFromLegacy(labelInterface.categorization),
            });
        }
        catch (e) {
            // TODO: need to define detailed messages
            throw new ValidationError('labelInterface JSON format error');
        }
    }
    toLegacy() {
        return Object.assign(Object.assign({}, this.objectDetection.toLegacy()), this.imageCategorization.toLegacy());
    }
    toJSON() {
        if (this.type === 'legacyImage') {
            return this.toLegacy();
        }
        // add more types
        return {};
    }
    /**
     * Summarize LabelResultDef instance
     * @returns Summary of LabelResultDef instance
     */
    summarizeToJSON() {
        // Object Detection
        const classIdToName = this.objectDetection.objectClasses.reduce((idMap, objectClass) => (Object.assign(Object.assign({}, idMap), { [objectClass.id]: objectClass.name })), {});
        const kpIdToName = this.objectDetection.keypointDefs.reduce((idMap, keypointDef) => (Object.assign(Object.assign({}, idMap), { [keypointDef.id]: keypointDef.name })), {});
        const classes = this.objectDetection.objectClasses.map(objectClass => (Object.assign({ name: objectClass.name, color: objectClass.color, annotationType: objectClass.annotationType }, (objectClass.annotationType === 'keypoint' && {
            keypointName: kpIdToName[objectClass.keypointInterfaceId],
        }))));
        const groups = this.objectDetection.objectGroups.map(objectGroup => ({
            name: objectGroup.name,
            objectClasses: objectGroup.objectClassIds.map(objectClass => classIdToName[objectClass]),
        }));
        return {
            dataType: this.dataType,
            objectDetection: {
                annotationTypes: this.objectDetection.annotationTypes,
                objectClasses: classes,
                objectGroups: groups,
                keypointDefs: this.objectDetection.keypointDefs.map(keypointDef => ({
                    name: keypointDef.name,
                    points: keypointDef.points.map(point => point.name),
                })),
            },
            imageCategorization: this.imageCategorization.tasks.map(task => task.summarizeToJSON()),
        };
    }
}
export { LabelResultDef };
