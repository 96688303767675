import { ObjectClassDef, ObjectGroupDef, } from './objectClassDef';
class ObjectTrackingDef {
    constructor(params) {
        this.annotationTypes = params.annotationTypes;
        this.objectClasses = params.objectClasses;
        this.objectGroups = params.objectGroups;
    }
    summarize() {
        const objectClassIdToName = this.objectClasses.reduce((idMap, objectClass) => (Object.assign(Object.assign({}, idMap), { [objectClass.id]: objectClass.name })), {});
        return {
            annotationTypes: this.annotationTypes,
            objectClasses: this.objectClasses.map(objectClass => objectClass.summarize()),
            objectGroups: this.objectGroups.map(objectGroup => objectGroup.summarize(objectClassIdToName)),
        };
    }
    static fromJSONObject(obj) {
        return new ObjectTrackingDef({
            annotationTypes: obj.annotationTypes,
            objectClasses: obj.objectClasses.map((el) => ObjectClassDef.fromJSONObject(el)),
            objectGroups: obj.objectGroups.map((el) => ObjectGroupDef.fromJSONObject(el)),
        });
    }
    getClassFromName(name) {
        for (const objectClass of this.objectClasses) {
            if (objectClass.name === name) {
                return objectClass;
            }
        }
        return null;
    }
}
export { ObjectTrackingDef };
